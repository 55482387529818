"use client"; // Error components must be Client components

import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <div className="flex min-h-[80vh] items-center justify-center">
      <div className="flex mt-1 text-2xl font-bold mx-3">
        <p>
          ¡Vaya! algo no ha funcionado como debería.
          <br /> Vuelve a intentarlo en unos minutos
        </p>
      </div>
    </div>
  );
}
